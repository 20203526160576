import React from "react";
import axios from "axios";
import Helmet from "react-helmet";
import { Provider } from "react-redux";
import { store, persistor } from "./src/store";
import { PersistGate } from "redux-persist/integration/react";

export const onInitialClientRender = () => {
  axios.defaults.baseURL = process.env.GATSBY_IP_ADDRESS;
};

export const onRouteUpdate = () => {
  axios.defaults.baseURL = process.env.GATSBY_IP_ADDRESS;
};

export const wrapRootElement = ({ element }) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Helmet>
        <script src="https://cdn.pagesense.io/js/asterra/d9e9d603bbb84127aaf5396531f667dd.js" />
      </Helmet>
      {element}
    </PersistGate>
  </Provider>
);
