import { createSlice } from "@reduxjs/toolkit";

const generalSlice = createSlice({
  name: "general",
  initialState: {
    isMetricOrUs: "Standard Metric",
    loginValues: { subProject: null },
    isLoading: false,
    loadingMsg: "Please Wait A Moment.",
    subProjectRecoverArr: [],
    allProjects: {
      recover: {},
      masterPlan: {},
      earthworks: {},
    },
    projectData: {},
    showSnackBar: false,
    snackBarMsg: "",
    searchTerm: "",
    currency: "USD $",
    decimalLocale: "en-US",
    displayedAvgLeakSizeValues: {
      main: "",
      service: "",
      customerSide: "",
      meter: "",
      valve: "",
      hydrant: "",
      fitting: "",
      curbstop: "",
    }
  },
  reducers: {
    setMetricOrUs: (state, action) => ({
      ...state,
      isMetricOrUs: action.payload,
    }),
    setMeasures: (state, action) => ({ ...state, ...action.payload }),
    login: (state, action) => ({ ...state, loginValues: action.payload }),
    addSubProject: (state, action) => ({
      ...state,
      loginValues: { ...state.loginValues, subProject: action.payload },
    }),
    logOut: (state) => ({
      ...state,
      loginValues: { ...state.loginValues, userId: null, subProject: null },
    }),
    setLoading: (state, action) => ({ ...state, isLoading: action.payload }),
    setLoadingMsg: (state, action) => ({
      ...state,
      loadingMsg: action.payload,
    }),
    setSubProjectRecoverArr: (state, action) => ({
      ...state,
      subProjectRecoverArr: action.payload,
    }),
    setProjectData: (state, action) => ({
      ...state,
      projectData: action.payload,
    }),
    toggleShowSnackBar: (state) => ({
      ...state,
      showSnackBar: !state.showSnackBar,
    }),
    setSnackBarMsg: (state, action) => ({
      ...state,
      showSnackBar: true,
      snackBarMsg: action.payload,
    }),
    setAllProjects: (state, action) => ({
      ...state,
      allProjects: action.payload,
    }),
    setProjectsSearchTerm: (state, action) => ({
      ...state,
      searchTerm: action.payload,
    }),
    setCurrency: (state, action) => ({
      ...state,
      currency: action.payload,
    }),
    setDecimalLocale: (state, action) => ({
      ...state,
      decimalLocale: action.payload,
    }),
    setDisplayedAvgLeakSizeValues: (state, action) => ({
      ...state,
      displayedAvgLeakSizeValues: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase("persist/REHYDRATE", (state, action) => {
      state.isMetricOrUs =
        action.payload?.general?.isMetricOrUs ?? state.isMetricOrUs;
      state.currency = action.payload?.general?.currency ?? state.currency;
      state.decimalLocale = action.payload?.general?.decimalLocale ?? state.decimalLocale;
    });
  },
});

// export all dispatch functions
export const {
  setMetricOrUs,
  login,
  addSubProject,
  logOut,
  setLoading,
  setLoadingMsg,
  setMeasures,
  setSubProjectRecoverArr,
  setProjectData,
  toggleShowSnackBar,
  setSnackBarMsg,
  setAllProjects,
  setProjectsSearchTerm,
  setCurrency,
  setDecimalLocale,
  setDisplayedAvgLeakSizeValues
} = generalSlice.actions;

export default generalSlice.reducer;
